var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{ref:"modalAnadirCompra",attrs:{"titulo":"Añadir orden de compra","no-aceptar":"","adicional":"Añadir"},on:{"adicional":_vm.postGuardarOrdenCompra}},[(_vm.cargando)?_c('cargando'):_vm._e(),_c('div',{staticClass:"row mx-0"},[_c('p',{staticClass:"col-12 text-center text-general f-12"},[_vm._v(" Indique en que momento y a que CEDIS llegarán los productos ")])]),_c('ValidationObserver',{ref:"validacion"},[_c('div',{staticClass:"row mx-0 mt-4"},[_c('div',{staticClass:"col-12"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Fecha"},scopedSlots:_vm._u([{key:"default",fn:function({errors}){return [_c('p',{staticClass:"label-mini"},[_vm._v(" Seleccionar fecha ")]),_c('el-date-picker',{staticClass:"w-100",attrs:{"size":"small","type":"date","placeholder":"Seleccionar","format":"d [de] MMMM [del] yyyy","value-format":"yyyy-MM-dd","picker-options":_vm.pickerOptions},model:{value:(_vm.fecha),callback:function ($$v) {_vm.fecha=$$v},expression:"fecha"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)]),_c('div',{staticClass:"row mx-0 mt-4"},[_c('div',{staticClass:"col"},[_c('p',{staticClass:"label-mini"},[_vm._v("Hora Inicio")]),_c('ValidationProvider',{attrs:{"rules":"required","name":"Hora Inicial"},scopedSlots:_vm._u([{key:"default",fn:function({errors}){return [_c('el-time-select',{staticClass:"w-100",attrs:{"size":"small","placeholder":"Hora inicio","format":"hh:mm a","value-format":"HH:mm","picker-options":{
                        start: '00:00',
                        step: '00:30',
                        end: '23:00',
                        format: 'hh:mm a',
                        valueFormat:'HH:mm',
                        maxTime: _vm.hora_final
                    }},model:{value:(_vm.hora_inicial),callback:function ($$v) {_vm.hora_inicial=$$v},expression:"hora_inicial"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col"},[_c('p',{staticClass:"label-mini"},[_vm._v("Hora Fin")]),_c('ValidationProvider',{attrs:{"rules":"required","name":"Hora Fin"},scopedSlots:_vm._u([{key:"default",fn:function({errors}){return [_c('el-time-select',{staticClass:"w-100",attrs:{"size":"small","placeholder":"Hora fin","format":"hh:mm a","value-format":"HH:mm","picker-options":{
                        start: '00:00',
                        step: '00:30',
                        end: '23:00',
                        format: 'hh:mm a',
                        valueFormat:'HH:mm',
                        minTime: _vm.hora_inicial
                    }},model:{value:(_vm.hora_final),callback:function ($$v) {_vm.hora_final=$$v},expression:"hora_final"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)]),_c('div',{staticClass:"row mx-0 mt-4"},[_c('div',{staticClass:"col-12 pr-0"},[_c('p',{staticClass:"label-mini"},[_vm._v(" Proveedor ")]),_c('el-select',{staticClass:"w-100",attrs:{"size":"small","clearable":true},on:{"clear":_vm.limpiarProveedor},model:{value:(_vm.id_proveedor),callback:function ($$v) {_vm.id_proveedor=$$v},expression:"id_proveedor"}},_vm._l((_vm.proveedores),function(proveedor){return _c('el-option',{key:proveedor.id,attrs:{"label":proveedor.nombre,"value":proveedor.id}})}),1)],1),_c('div',{staticClass:"col-12 pr-0 mt-4"},[_c('p',{staticClass:"label-mini"},[_vm._v(" Comentario ")]),_c('ValidationProvider',{attrs:{"rules":"max:500","name":"Comentario"},scopedSlots:_vm._u([{key:"default",fn:function({errors}){return [_c('el-input',{staticClass:"w-100",attrs:{"rows":4,"type":"textarea"},model:{value:(_vm.comentario),callback:function ($$v) {_vm.comentario=$$v},expression:"comentario"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)]),_c('div',{staticClass:"row mx-0 pl-3 mt-3"},[_c('div',{staticClass:"col-12 d-flex"},[_c('el-upload',{ref:"upload",attrs:{"action":"https://jsonplaceholder.typicode.com/posts/","on-change":_vm.changeFile,"on-remove":_vm.removeFile,"auto-upload":false,"multiple":false,"limit":1,"accept":".xlsx"}},[_c('div',{staticClass:"bg-general2 text-white br-8 px-3 py-1 f-16 cr-pointer shadow"},[_vm._v(" Click para subir archivo ")])])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }